<!--
 * @Author: DQL
 * @Date: 2020-03-09 17:00:21
 * @LastEditors: cxb chenxiaobiao@hongmeng-info.com
 * @LastEditTime: 2023-03-03 17:09:20
 * @Description: file content
 -->
<template>
  <div
    class="c-login-wrap"
  >
    <div
      class="c-login__main"
    >
      <h2
        class="c-login__title"
      >
        <img
          style="width: 90px;height:90px"
          :src="appLogo"
        >
        <span>{{ SCREEN_TITLE_NAME }}</span>
      </h2>
      <div
        class="l-login__body"
      >
        <div class="c-login__text-wrap">
          <p class="text-wrap__title">
            {{ app.welcome }}
          </p>
          <p class="text-wrap__title">
            欢迎使用  {{ SCREEN_TITLE_NAME }}
          </p>
          <p class="text-wrap__body">
            {{ app.welcome_en }}
          </p>
        </div>
        <div
          v-loading="logining"
          class="c-login__form-wrap"
          element-loading-text="正在登入，请稍后……"
        >
          <div class="c-login__form-title">
            <span
              class="c-login__ftitle"
              :class="{'is-active': loginType==='0'}"
              @click="changeLoginType('0')"
            >账号登录</span>
            <span
              class="c-login__ftitle"
              :class="{'is-active': loginType==='9'}"
              @click="changeLoginType('9')"
            >微信登录</span>
            <span
              class="c-login__ftitle"
              :class="{'is-active': loginType==='8'}"
              @click="changeLoginType('8')"
            >人脸识别</span>
          </div>
          <div v-if="loginType!=='9' && loginType!=='8'">
            <!-- <div
              class="c-login__form-title"
            >
              用户登录
            </div> -->
            <div
              class="o-loginT-tips"
              style="margin-top: 48px;"
            >
              请使用账号或手机号登录
            </div>
            <el-form
              ref="fd"
              class="c-login__form"
              :model="fd"
              :rules="fdRules"
              size="normal"
              label-width="0px"
            >
              <el-form-item
                prop="account"
              >
                <el-input
                  v-model.trim="fd.account"
                  type="text"
                  prefix-icon="el-icon-user"
                  placeholder="请输入账号/手机号"
                  class="login_input_icon"
                  @keyup.native.enter="handleSubmit"
                />
              </el-form-item>
              <el-form-item
                prop="password"
              >
                <el-input
                  v-model="fd.password"
                  type="password"
                  prefix-icon="el-icon-lock"
                  placeholder="请输入密码"
                  @keyup.native.enter="handleSubmit"
                />
              </el-form-item>
              <!-- <el-form-item
              class="o-forget-pass-bar"
            >
              <span
                class="u-pointer"
                @click="$router.push({path: '/forgetPassword'})"
              >忘记密码？</span>
            </el-form-item> -->
              <el-form-item
                class="o-login-bar"
              >
                <el-button
                  size="normal"
                  type="primary"
                  :loading="logining"
                  @click.native.prevent="handleSubmit"
                >
                  <span class="u-mg-l-12">登录</span>
                </el-button>
              </el-form-item>
              <!-- <el-form-item
              class="o-register-bar"
            >
              <el-divider>
                <span
                  class="o-register-link"
                  @click="$router.push({name: 'register'})"
                >立即注册</span>
              </el-divider>
            </el-form-item> -->
            </el-form>
          </div>
          <div
            v-else-if="loginType === '8'"
            class="o-renlianshibie-body"
          >
            <!-- <div
              class="c-login__form-title"
            >
              人脸识别
            </div> -->
            <face
              ref="facelogin"
              :face-error="faceLoginError"
              :warning-text="sbWarningText"
              @callback="handleSbSubmit"
            />
          </div>
          <div
            v-else
            class="o-qrcode-body"
          >
            <div
              v-if="!firstScanFlag"
              style="margin-bottom: 50px;"
            >
              <!-- <div
                class="c-login__form-title"
              >
                微信扫码，安全登录
              </div> -->
              <p class="o-qrcode-desc">
                扫码并关注<span>智慧消防公众号</span>安全快捷登录
              </p>
              <div

                class="o-qrcode-img"
              >
                <img
                  v-show="ewm"
                  style="width: 300px;height: 300px;"
                  :src="ewm"
                >
                <div
                  v-show="!ewm"
                  class="o-qrcode-error u-text-primary"
                >
                  正在加载二维码...
                </div>
                <div
                  v-if="qrcodeScanFlag !== '0'"
                  class="o-qrcode-error"
                >
                  <i
                    v-if="qrcodeScanFlag === '9'"
                    class="kestrel-icon ki-sync"
                    @click="handleRefreshQrcode"
                  />
                  <p v-if="qrcodeScanFlag === '9'">
                    二维码已失效，点击重新获取
                  </p>
                </div>
              </div>
            </div>
            <wx-scan-bind
              v-if="firstScanFlag"
              :openid="openid"
              @callback="handleScanSubmit(openid)"
            />
          </div>
        </div>
      </div>
      <div
        class="l-login__footer"
      >
        <small
          class="c-login__copyright"
        >{{ cr }}</small>
      </div>
    </div>
  </div>
</template>

<script>
import config from '@/config.js';
import { loginSys, ZbryQd, createTmpQrcode, getQrcodeOpenid, wxscanLogin, rlsbLogin, queryEnvDetail } from '@/features/feature.common.service';
import WxScanBind from './wxScanBind.vue';
import Face from './face.vue';

export default {
  name: 'Login',
  components: {
    WxScanBind: WxScanBind,
    Face: Face
  },
  props: {
    warningText: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      appLogo: process.env.BASE_URL + 'assets/logo.png',
      app: config.app,
      SCREEN_TITLE_NAME: config.app.name,
      cr: config.copyright,
      logining: false, // 登录ing
      loginType: '0', // 登录类型：0:账号密码登录 9：二维码登录, 8: 人脸识别
      ewm: '', // 二维码
      ewmClock: null, // 二维码锁定
      qrcodeScanFlag: '0', // 二维码扫码标志, ‘0’：待扫码，‘1’：扫码成功，‘9’：扫码失败或超时
      firstScanFlag: false, // 首次扫码登录标志
      openid: '',
      faceLoginError: false,
      sbWarningText: '',
      /* 表单参数 Start */
      fd: {
        account: '',
        password: ''
      },
      /* 表单参数 End */

      /* 表单校验规则 Start */
      fdRules: {
        account: [
          { required: true, message: '请输入账号/手机号', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ]
      },
      /* 表单校验规则 End */
      loginError: false
    };
  },
  computed: {
    // 用户信息
    currentUser () {
      return this.$HMStoreService.getUserinfo();
    }
  },
  methods: {
    /**
     * @description: 登录类型切换
     * @return {*}
     */
    changeLoginType (_type) {
      const _this = this;
      if (_type !== '9') {
        clearInterval(_this.ewmClock);
        _this.ewmClock = null;
      }
      if (_this.loginType === '8' && _type !== '8') {
        // this.$refs.facelogin.handleCancel();
      }

      _this.loginType = _type;
      if (_type === '9') {
        this.handleRefreshQrcode();
      }
      if (_type === '8') {
        this.renlianshibieWarning = false;
        this.faceLoginError = false;
        this.sbWarningText = '';
      } else {
        this.faceLoginError = true;
        this.sbWarningText = '';
      }
    },
    /**
     * @description: 登录
     * @param {*}
     * @return {*}
     */
    handleSubmit (ev) {
      if (this.loginError) {
        return;
      }
      if (ev !== undefined) {
        ev.srcElement.blur();
      }
      const _this = this;
      this.$refs.fd.validate((valid) => {
        if (valid) {
          _this.logining = true;
          // 清除cookie，防止用户直接关闭标签操作，重新登录且cookie没更新
          _this.$HMCookies.unset(config.localTokenName);
          loginSys(_this.fd)
            .then(rsp => {
              const userinfo = rsp.data;

              const token = rsp.headers[config.serverHeaderTokenName];
              _this.$HMCookies.set(config.localTokenName, token);

              _this.$HMStoreService.updateUserinfo(userinfo); // 更新用户信息
              _this.$HMStoreService.updateMenu(userinfo.menuTree); // 更新用户权限菜单树
              _this.handleLoginSuccess(userinfo);
              this.zbryQd(userinfo);
            })
            .catch(err => {
              if (err.data.errorCode === '4010' || err.data.errorCode === '4000') {
                _this.loginError = true;
                _this.$alert(err.data.errorMsg, '提示', {
                  confirmButtonText: '确定',
                  type: 'error',
                  callback: action => {
                    _this.loginError = false;
                  }
                });
              }
            })
            .finally(() => {
              _this.logining = false;
            });
        }
      });
    },
    /**
     * @description: 登录成功处理事件
     * @param {*}
     * @return {*}
     */
    handleLoginSuccess (_userinfo) {
      // 登录后用户信息没有身份信息，跳转选身份页面
      if (!_userinfo.capacity) {
        this.$router.push({ path: '/switchSubject' });
      } else {
        this.$router.push({ path: '/' });
      }
    },
    /**
     * 值班人员登录签到
     */
    zbryQd (_userinfo) {
      console.log(_userinfo);
      _userinfo.roles.forEach(e => {
        if (e === 'IFFCLOUDZBY') {
          ZbryQd(_userinfo.account)
            .then(rsp => {
              console.log('签到成功！');
            })
            .catch(err => {
              console.error('值班人员登录签到出错！', err);
            });
          return '';
        }
      });
    },
    /**
     * @description: 检测登录情况
     * @param {*}
     * @return {*}
     */
    handleCheckLogin () {
      const hasToken = this.$HMCookies.get(config.localTokenName) || false;
      // 如果cookie 有token ，数据管理存在用户信息，且用户有登录身份信息，进入系统
      if (hasToken && this.currentUser && this.currentUser.capacity) {
        this.$router.push({ path: '/' });
      } else if (hasToken && this.currentUser && !this.currentUser.capacity) {
        // 如果cookie 有token ，数据管理存在用户信息，但是用户没有登录身份信息，跳转选择身份页面
        this.$router.push({ path: '/switchSubject' });
      } else {
        document.getElementById('preloading').style.display = 'none';
      }
    },
    /**
     * @description: 二维码刷新获取
     * @return {*}
     */
    handleRefreshQrcode () {
      const _this = this;
      _this.qrcodeScanFlag = '0';
      this.ewm = '';
      let ewmSceneId;
      const ewmBegintime = Date.now();
      const queryOpenId = function () {
        getQrcodeOpenid(ewmSceneId).then(resp => {
          _this.openid = resp.data.data;
          if (_this.openid) {
            if (!_this.logining) {
              _this.handleScanSubmit(_this.openid);
            }
            _this.handleResetQrcode();
          }
        });
        if (_this.ewmClock && Date.now() - ewmBegintime > 60 * 1000) {
          // 超时未扫码
          _this.handleResetQrcode();
          _this.qrcodeScanFlag = '9';
        }
      };
      createTmpQrcode('SCAN_LOGIN')
        .then(resp => {
          ewmSceneId = resp.data.data.sceneStr;
          _this.ewm = `data:image/png;base64,${resp.data.data.ewm}`;
          _this.ewm = _this.ewm.replace(/[\r\n]/g, '');
          if (!_this.ewmClock) _this.ewmClock = setInterval(queryOpenId, 3 * 1000);
        });
    },

    /**
     * @description: 二维码过期
     * @return {*}
     */
    handleResetQrcode () {
      clearInterval(this.ewmClock);
      this.ewmClock = null;
      this.qrcodeScanFlag = '0';
    },

    /**
     * @description: 二维码扫码登录
     * @return {*}
     * @param {*} ev
     */
    handleScanSubmit (_openid) {
      if (this.loginError) {
        return;
      }
      const _this = this;
      _this.firstScanFlag = false;
      _this.logining = true;
      // 清除cookie，防止用户直接关闭标签操作，重新登录且cookie没更新
      _this.$HMCookies.unset(config.localTokenName);
      wxscanLogin(_openid)
        .then(rsp => {
          if (!rsp.data || rsp.data === null) {
            _this.firstScanFlag = true;
            return;
          }
          const userinfo = rsp.data;

          const token = rsp.headers[config.serverHeaderTokenName];
          _this.$HMCookies.set(config.localTokenName, token);

          _this.$HMStoreService.updateUserinfo(userinfo); // 更新用户信息
          _this.$HMStoreService.updateMenu(userinfo.menuTree); // 更新用户权限菜单树
          _this.handleLoginSuccess(userinfo);
        })
        .catch(err => {
          if (err.data.errorCode === '4010' || err.data.errorCode === '4000') {
            _this.loginError = true;
            _this.$alert(err.data.errorMsg, '提示', {
              confirmButtonText: '确定',
              type: 'error',
              callback: action => {
                _this.loginError = false;
              }
            });
          }
          _this.qrcodeScanFlag = '9';
          _this.firstScanFlag = false;
        })
        .finally(() => {
          _this.logining = false;
          console.log('firstScanFlag:', _this.firstScanFlag);
        });
    },
    /**
     * @description: 人脸识别登录
     * @return {*}
     * @param {*} ev
     */
    handleSbSubmit (data) {
      const _this = this;
      _this.sbWarningText = '';
      _this.faceLoginError = false;
      if (data == null || data.images == null || data.images.lenth === 0) {
        _this.loginError = true;
        _this.$alert('人脸识别登录错误，未能检测到人脸！', '提示', {
          confirmButtonText: '确定',
          type: 'error',
          callback: action => {
            _this.loginError = false;
            _this.faceLoginError = true;
          }
        });
        return;
      }

      _this.logining = true;
      rlsbLogin(data)
        .then(rsp => {
          if (rsp.data.code && rsp.data.code !== '0') {
            _this.loginError = false;
            _this.faceLoginError = true;
            _this.sbWarningText = '人脸识别失败';
            _this.$alert(rsp.data.msg || rsp.data.message, '提示', {
              confirmButtonText: '确定',
              type: 'error',
              callback: action => {

              }
            });
            return;
          }
          const userinfo = rsp.data;
          const token = rsp.headers[config.serverHeaderTokenName];
          _this.$HMCookies.set(config.localTokenName, token);

          _this.$HMStoreService.updateUserinfo(userinfo); // 更新用户信息
          _this.$HMStoreService.updateMenu(userinfo.menuTree); // 更新用户权限菜单树
          _this.handleLoginSuccess(userinfo);
        })
        .catch(err => {
          if (err.data.errorCode) {
            _this.loginError = true;
            _this.sbWarningText = '人脸识别失败';
            _this.$alert(err.data.errorMsg || err.data.message, '提示', {
              confirmButtonText: '确定',
              type: 'error',
              callback: action => {
                _this.loginError = false;
                _this.faceLoginError = true;
              }
            });
          }
          _this.sbWarningText = '人脸识别失败';
          _this.loginError = false;
          _this.faceLoginError = true;
        }).finally(() => {
          _this.logining = false;
        });
    },
    getEnvDetail () {
      queryEnvDetail({ ssdw: 'hongmeng' })
        .then(resp => {
          console.log(resp.data);
          resp.data.forEach(item => {
            console.log('item', item);
            if (item.envId === 'SCREEN_TITLE_NAME') {
              this.SCREEN_TITLE_NAME = item.envValue;
            }
          });
        });
    }
  },
  created () {
    this.getEnvDetail();
    this.ewm = '';
    this.handleCheckLogin();
  },
  destroyed () {
    clearInterval(this.ewmClock);
  }
};

</script>

<style lang="scss">
@import './login.scss';
</style>
